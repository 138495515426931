.home {
    .top-bannar {
        position: relative;
        min-height: 1051px;

        @media screen and (max-width: 991px) {
            min-height: 100%;
        }

        .home-add-nav {
            position: absolute;
            z-index: 10;
            left: 0;
            right: 0;
        }

        .add-bannar {
            // position: relative;
            width: 100%;
            height: 100%;
            overflow-x: hidden;

            @media screen and (max-width: 460px) {
                overflow-x: unset;
            }

            .add-bannar-content {

                .content-column {
                    padding: 204px 0px 180px 0px;

                    @media screen and (max-width: 768px) {
                        padding: 0;
                        padding-top: 204px;
                    }

                    @media screen and (max-width: 600px) {
                        padding-top: 204px;
                        padding-right: calc(var(--bs-gutter-x)* .5);
                        padding-left: calc(var(--bs-gutter-x)* .5);
                    }


                    .content {
                        gap: 19px;

                        h1 {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 60px;
                            line-height: 71px;
                            color: #252525;

                            @media screen and (max-width: 1400px) {
                                font-size: 50px;
                                line-height: 55px;
                            }

                            @media screen and (max-width: 768px) {
                                font-size: 40px;
                                line-height: 45px;
                            }

                            span {
                                font-family: 'Gilroy';
                                font-style: normal;
                                font-weight: 800;
                                font-size: 60px;
                                line-height: 71px;
                                color: #0094DA;

                                @media screen and (max-width: 1400px) {
                                    font-size: 50px;
                                    line-height: 55px;
                                }

                                @media screen and (max-width: 768px) {
                                    font-size: 40px;
                                    line-height: 45px;
                                }
                            }
                        }

                        p {
                            width: 353px;
                            font-family: "Gilroy";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            color: #000000;

                            @media screen and (max-width: 400px) {
                                width: 100%;
                            }
                        }

                        button {
                            box-sizing: border-box;
                            width: 231.53px;
                            height: 60px;
                            border: 1px solid #FFFFFF;
                            border-radius: 64px;
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                        }

                        .buttons {
                            gap: 20px;

                            @media screen and (max-width: 768px) {
                                flex-direction: column;
                            }

                            @media screen and (max-width: 460px) {
                                width: 100%;
                                height: auto;
                                flex-direction: row;
                            }

                            .download-blue {
                                background: #0094DA;
                                color: white;
                            }

                            .download-white {
                                background: #FFFFFF;
                                border: 1px solid #CECECE;
                                border-radius: 64px;
                                color: #1C1D1F;
                            }
                        }
                    }
                }

                .add-image-column {
                    .mobile-add {
                        img {
                            width: 100dvw;
                            max-height: 800px;
                        }
                    }
                }

                .add-image-column-md {
                    .mobile-add-image {
                        img {
                            width: 500px;
                            height: 500px;
                            position: absolute;
                            right: 0;
                            top: 332px;

                            @media screen and (max-width: 850px) {
                                width: 360px;
                                top: 314px;
                            }

                            @media screen and (max-width: 768px) {
                                top: 309px;
                            }

                            @media screen and (max-width: 600px) {
                                margin-top: 40px;
                                top: 350px;
                                height: 300px;
                                // position: relative;
                            }

                            @media screen and (max-width: 500px) {
                                width: 300px;
                                top: 380px;
                                height: 300px;
                                // position: relative;
                            }

                            @media screen and (max-width: 470px) {
                                top: 450px;
                                height: 300px;
                            }

                            @media screen and (max-width: 460px) {
                                top: 100%;
                                height: auto;
                                position: relative;
                            }
                        }
                    }
                }
            }

            .mobile-image {

                img {
                    width: 900px;
                    height: 1100px;
                    position: absolute;
                    top: 200px;
                    right: 0px;

                    @media screen and (max-width: 1400px) {
                        width: 848px;
                        height: 848px;
                    }

                    @media screen and (max-width: 1100px) {
                        width: 727px;
                        height: 848px;

                    }

                    // width: 1100px;
                    // height: 738px;


                    // @media screen and (max-width: 1600px) {
                    //     width: 700px;
                    //     height: 800px;
                    // }
                }
            }

            // .add-image-column {
            //     position: absolute;
            //     right: -100px;

            //     .mobile-add {
            //         // max-height: 900px;
            //         // background-size: cover;

            //         .blue-bg-img {
            //             width: 100dvw;
            //             max-height: 750px;
            //             // background-size: cover;
            //         }

            //         .mobile-image {
            //             position: absolute;
            //             top: 200px;
            //             width: 100%;
            //             height: 100%;

            //             img {
            //                 width: 800px;
            //                 height: 1000px;
            //             }
            //         }
            //     }
            // }
        }
    }
}

.about-us {

    .company-overview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 59px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;

            @media screen and (max-width: 991px) {
                font-size: 40px;
                line-height: 45px;
            }
        }

        p {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;

            @media screen and (max-width: 991px) {
                font-size: 13px;
                line-height: 22px;
            }
        }
    }

    .content-top {
        h2 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 68px;
            line-height: 81px;
            text-transform: capitalize;
            color: #252525;

            @media screen and (max-width: 991px) {
                font-size: 45px;
                line-height: 50px;
            }

            @media screen and (max-width: 600px) {
                font-size: 40px;
                line-height: 45px;
            }
        }

        span {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 68px;
            line-height: 82px;
            text-transform: capitalize;
            color: #0094DA;

            @media screen and (max-width: 991px) {
                font-size: 45px;
                line-height: 50px;
            }

            @media screen and (max-width: 600px) {
                font-size: 40px;
                line-height: 45px;
            }
        }

        p {
            width: 70%;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            color: #000000;
        }

    }

    .content {
        @media screen and (max-width: 991px) {
            gap: 30px;
        }

        .mission-statement {
            @media screen and (max-width: 991px) {
                text-align: center;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            h2 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 46px;
                line-height: 79px;
                color: #000000;

                @media screen and (max-width: 991px) {
                    font-size: 40px;
                    line-height: 45px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 35px;
                    line-height: 30px;
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 46px;
                    line-height: 79px;
                    color: #0094DA;

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                        line-height: 45px;
                    }

                    @media screen and (max-width: 600px) {
                        font-size: 35px;
                        line-height: 40px;
                    }
                }
            }

            p {
                width: 70%;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 25px;
                color: #000000;
            }
        }
    }

    .image {
        @media screen and (max-width: 991px) {
            margin-top: 40px;
        }

        img {
            filter: drop-shadow(-30.7px 32.6px 14.6px hsl(0deg 0% 0% / 0.27));
            width: 502px;
            height: auto;

            @media screen and (max-width: 1250px) {
                width: 520px !important;
                height: auto;
            }

            @media screen and (max-width:1050px) {
                width: 100% !important;
            }

            @media screen and (max-width: 991px) {
                width: 520px !important;
            }

            @media screen and (max-width: 600px) {
                width: 100% !important;
            }
        }
    }

    .image-col {
        @include background-circle;

        .image {
            img {
                filter: drop-shadow(-30.7px 32.6px 14.6px hsl(0deg 0% 0% / 0.27));
                width: 502px;
                height: auto;

                @media screen and (max-width: 1250px) {
                    width: 520px !important;
                    height: auto;
                }

                @media screen and (max-width:1050px) {
                    width: 100% !important;
                }

                @media screen and (max-width: 991px) {
                    width: 520px !important;
                }

                @media screen and (max-width: 600px) {
                    width: 100% !important;
                }
            }
        }
    }
}

.features {
    .content {
        .discover {
            gap: 20px;

            h1 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 68px;
                line-height: 81px;
                color: #000000;
                text-align: center;

                @media screen and (max-width: 991px) {
                    font-size: 45px;
                    line-height: 50px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 40px;
                    line-height: 45px;
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 68px;
                    line-height: 81px;
                    color: #0094DA;

                    @media screen and (max-width: 991px) {
                        font-size: 45px;
                        line-height: 50px;
                    }

                    @media screen and (max-width: 600px) {
                        font-size: 40px;
                        line-height: 45px;
                    }
                }
            }

            p {
                width: 90%;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                color: #000000;
                text-align: center;
            }
        }
    }

    .user-friendly {
        .row {
            display: flex;
            justify-content: center;
            align-items: center;
        }


        .content {
            @media screen and (max-width: 991px) {
                text-align: center;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .text {
                h1 {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 46px;
                    line-height: 69px;
                    color: #0094DA;

                    span {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 46px;
                        line-height: 69px;
                        color: #000000;
                    }
                }
            }

            p {
                padding: 0;
                margin: 0;
                width: 90%;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #000000;
            }

        }

        .image-col {
            @include background-circle;

            .image {
                position: relative;

                img {
                    filter: drop-shadow(-30.7px 32.6px 14.6px hsl(0deg 0% 0% / 0.27));
                    width: 502px;
                    height: auto;

                    @media screen and (max-width: 1250px) {
                        width: 400px !important;
                        height: auto;
                    }

                    @media screen and (max-width:1050px) {
                        width: 100% !important;
                    }

                    @media screen and (max-width: 991px) {
                        width: 520px !important;
                    }

                    @media screen and (max-width: 600px) {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .your-booking {
        .row {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .image-col {
            @include background-circle;

            .image {
                position: relative;

                img {
                    filter: drop-shadow(-30.7px 32.6px 14.6px hsl(0deg 0% 0% / 0.27));
                    width: 502px;
                    height: auto;

                    @media screen and (max-width: 1250px) {
                        width: 400px !important;
                        height: auto;
                    }

                    @media screen and (max-width:1050px) {
                        width: 100% !important;
                    }

                    @media screen and (max-width: 991px) {
                        width: 520px !important;
                    }

                    @media screen and (max-width: 600px) {
                        width: 100% !important;
                    }
                }
            }
        }

        .content {
            @media screen and (max-width: 991px) {
                text-align: center;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }

            .text {
                h1 {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 46px;
                    line-height: 55px;
                    color: #000000;
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 46px;
                    line-height: 55px;
                    color: #0094DA;
                }
            }

            p {
                margin-top: 20px;
                padding: 0;
                width: 90%;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #000000;
            }
        }
    }
}

.contact-us {
    .text {
        h1 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 68px;
            line-height: 81px;
            color: #252525;

            @media screen and (max-width: 768px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media screen and (max-width: 500px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        p {
            width: 90%;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            color: #000000;

            @media screen and (max-width: 500px) {
                font-size: 13px;
                line-height: 20px;
            }

        }
    }

    .box {
        .get-in-touch {
            gap: 30px;
            background: #F8F8F8;
            border-radius: 20px;

            @media screen and (max-width: 991px) {
                flex-direction: column;
                gap: 0px;
            }

            .form-content {
                padding: 50px 30px 50px 50px;
                justify-content: start;

                .top-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    h1 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 54px;
                        line-height: 66px;
                        color: #070707;

                        @media screen and (max-width: 991px) {
                            font-size: 44px;
                            line-height: 50px;
                        }

                        span {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 54px;
                            line-height: 66px;
                            color: #30A8E1;

                            @media screen and (max-width: 991px) {
                                font-size: 44px;
                                line-height: 50px;
                            }

                            @media screen and (max-width: 991px) {
                                font-size: 38px;
                                line-height: 45px;
                            }
                        }
                    }

                    p {
                        width: 70%;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.01em;
                        color: #B1B5B6;
                        text-align: start;

                        @media screen and (max-width: 991px) {
                            font-size: 13px;
                            line-height: 22px;
                        }

                    }
                }

                .form-contact-us {
                    .contacts {
                        @media screen and (max-width: 600px) {
                            flex-direction: column;
                            gap: 18px;
                        }

                        .data {
                            .text {
                                text-align: start;

                                p {
                                    margin: 0;
                                    padding: 0;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    letter-spacing: 0.02em;
                                    color: #B1B5B6;
                                    flex: none;
                                    flex-grow: 0;
                                }

                                span {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 20px;
                                    letter-spacing: 0.02em;
                                    color: #39ACE7;
                                    flex: none;
                                    order: 1;
                                    flex-grow: 0;
                                }
                            }
                        }
                    }
                }
            }

            .location {
                @media screen and (max-width: 991px) {
                    border-radius: 30px;
                    height: 300px;
                    width: 100%;
                }

                .map {
                    @media screen and (max-width: 991px) {
                        border-radius: 30px;
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.terms-and-condition {
    @include theme-heading-para;
}