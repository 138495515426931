@mixin btn-nav-bar {
    box-sizing: border-box;
    width: 180px;
    height: 60px;
    background: #0094DA;
    border: 1px solid #FFFFFF;
    border-radius: 64px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}

@mixin shadow {
    background: rgba(0, 0, 0, 0.4);
    box-shadow: -95.5584px 95.5584px 119.448px rgba(0, 0, 0, 0.4);
    border-radius: 50px;
}


@mixin theme-row {
    .row {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .your-one-stop-component-md {
        @media screen and (max-width: 991px) {
            margin-bottom: 40px
        }
    }

    .image {
        position: relative;

        img {
            width: 502px;
            height: auto;
            filter: drop-shadow(-30.7px 32.6px 14.6px hsl(0deg 0% 0% / 0.27));

            @media screen and (max-width: 1250px) {
                width: 520px !important;
                height: auto;
            }

            @media screen and (max-width:1050px) {
                width: 100% !important;
            }

            @media screen and (max-width: 991px) {
                width: 520px !important;
            }

            @media screen and (max-width: 600px) {
                width: 100% !important;
            }


        }
    }

    .image-content {
        position: relative;

        .image {
            position: relative;

            img {
                filter: drop-shadow(-30.7px 32.6px 14.6px hsl(0deg 0% 0% / 0.27));
                width: 502px;
                height: auto;

                @media screen and (max-width: 1250px) {
                    width: 400px !important;
                    height: auto;
                }

                @media screen and (max-width:1050px) {
                    width: 100% !important;
                }

                @media screen and (max-width: 991px) {
                    width: 520px !important;
                }

                @media screen and (max-width: 600px) {
                    width: 100% !important;
                }

            }
        }

        .map-img {
            position: absolute;
            top: 14%;
            left: 60%;

            @media screen and (max-width: 1400px) {
                top: 15%;
                left: 70%;
            }

            @media screen and (max-width: 1200px) {
                top: 0%;
                left: 65%;
            }

            @media screen and (max-width: 1050px) {
                top: 0%;
                left: 70%;
            }

            @media screen and (max-width: 991px) {
                left: 60%;
            }

            @media screen and (max-width: 768px) {
                left: 70%;
            }

            img {
                width: 130px;
                height: 287px;

                @media screen and (max-width: 768px) {
                    width: 100px;
                    height: 200px;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .text {
            h2 {
                font-family: "Gilroy-ExtraBold" !important;
                font-style: normal;
                font-weight: 700;
                font-size: 55px;
                line-height: 64px;
                color: #000000;

                @media screen and (max-width: 991px) {
                    font-size: 45px;
                    line-height: 50px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 40px;
                    line-height: 45px;
                }

            }

            span {
                font-family: "Gilroy-ExtraBold" !important;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 55px;
                line-height: 64px;
                color: #0094DA;

                @media screen and (max-width: 991px) {
                    font-size: 45px;
                    line-height: 50px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 40px;
                    line-height: 45px;
                }

            }
        }

        p {
            width: 75%;
            font-family: 'Gilroy-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            color: #000000;
            opacity: 0.5;
        }
    }
}

@mixin background-circle {
    background-image: url("../images/bg-feature.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

@mixin theme-heading-para {
    h1 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 58px;
        line-height: 68px;
        text-transform: capitalize;
        color: #252525;

        @media screen and (max-width: 768px) {
            font-size: 40px;
            line-height: 50px;
        }

        @media screen and (max-width: 500px) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    p {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #000000;

        @media screen and (max-width: 500px) {
            font-size: 13px;
            line-height: 20px;
        }
    }
}