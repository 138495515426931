.application-feature {
    background-image: url("../images/bg-feature.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    .application_feature_component {
        padding-inline: 20px;
        padding-block: 50px;

        .headers {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            h1 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 50px;
                line-height: 59px;
                color: #000000;

                @media screen and (max-width: 991px) {
                    font-size: 40px !important;
                    line-height: 45px !important;
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 50px;
                    line-height: 59px;
                    color: #0094DA;

                    @media screen and (max-width: 991px) {
                        font-size: 40px !important;
                        line-height: 45px !important;
                    }
                }
            }
        }

        .mobile_section {
            .image_section {
                img {
                    height: 600px;
                    object-fit: contain;
                    // box-shadow: -95.5584px 95.5584px 119.448px rgba(0, 0, 0, 0.4);
                    @include shadow;
                }
            }

            .description {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 40px;

                h4 {
                    font-family: "Gilroy-ExtraBold" !important;
                    text-transform: capitalize;
                    font-size: 18px;
                }

                .details {
                    text-transform: capitalize;
                    font-size: 16px;
                    color: #9d9d9d;
                }
            }

            .description-md {
                @media screen and (max-width: 575px) {
                    margin-top: 40px;

                }
            }


        }
    }
}

.your-one-stop {
    background-color: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    @include theme-row;
    padding: 50px 0px;
}

.easy-way {
    @include theme-row;

    .image-col {
        @include background-circle
    }
}

.app-slider {
    background-color: #F6F6F6;
    padding: 100px 0px 0px 0px;

    .home-slider-componenet {
        .top-text {
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h1 {
                font-family: "Gilroy-ExtraBold" !important;
                font-style: normal;
                font-weight: 700;
                font-size: 70px;
                line-height: 79px;
                color: #0094D9;

                span {
                    font-family: "Gilroy-ExtraBold" !important;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 70px;
                    line-height: 79px;
                    /* identical to box height, or 113% */
                    color: #000000;
                }
            }

            p {
                width: 60%;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
                opacity: 0.5;
            }
        }

        .slider-component {

            *,
            *::before,
            *::after {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                list-style-type: none;
            }

            :root {
                --primary: #6a59ff;
                --white: #ffffff;
                --bg: #f5f5f5;
            }

            html {
                font-size: 62.5%;
                scroll-behavior: smooth;
            }

            @media (min-width: 1440px) {
                html {
                    zoom: 1.5;
                }
            }

            @media (min-width: 2560px) {
                html {
                    zoom: 1.7;
                }
            }

            @media (min-width: 3860px) {
                html {
                    zoom: 2.5;
                }
            }

            ::-webkit-scrollbar {
                width: 1.3rem;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 1rem;
                background: #797979;
                transition: all 0.5s ease-in-out;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #222224;
            }

            ::-webkit-scrollbar-track {
                background: #f9f9f9;
            }

            body {
                font-size: 1.6rem;
                background: var(--bg);
            }

            .container {
                max-width: 124rem;
                padding: 4rem 1rem;
                margin: 0 auto;
            }

            .heading {
                padding: 1rem 0;
                font-size: 3.5rem;
                text-align: center;
            }

            .swiper_container {
                height: 55rem;
                padding: 2rem 0;
                position: relative;
            }

            .swiper-slide {
                width: 37rem;
                height: 42rem;
                position: relative;
            }

            @media (max-width: 500px) {
                .swiper_container {
                    height: 47rem;
                }

                .swiper-slide {
                    width: 28rem !important;
                    height: 36rem !important;
                }

                .swiper-slide img {
                    width: 28rem !important;
                    height: 36rem !important;
                }
            }

            .swiper-slide img {
                width: 35rem;
                height: 735px;
                border-radius: 2rem;
                object-fit: contain;
            }

            .swiper-slide-shadow-left,
            .swiper-slide-shadow-right {
                display: none;
            }

            .slider-controler {
                position: relative;
                bottom: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .slider-controler .swiper-button-next {
                left: 58% !important;
                transform: translateX(-58%) !important;
            }

            @media (max-width: 990px) {
                .slider-controler .swiper-button-next {
                    left: 70% !important;
                    transform: translateX(-70%) !important;
                }
            }

            @media (max-width: 450px) {
                .slider-controler .swiper-button-next {
                    left: 80% !important;
                    transform: translateX(-80%) !important;
                }
            }

            @media (max-width: 990px) {
                .slider-controler .swiper-button-prev {
                    left: 30% !important;
                    transform: translateX(-30%) !important;
                }
            }

            @media (max-width: 450px) {
                .slider-controler .swiper-button-prev {
                    left: 20% !important;
                    transform: translateX(-20%) !important;
                }
            }

            .slider-controler .slider-arrow {
                background: var(--white);
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 50%;
                left: 42%;
                transform: translateX(-42%);
                filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
            }

            .slider-controler .slider-arrow ion-icon {
                font-size: 2rem;
                color: #222224;
            }

            .slider-controler .slider-arrow::after {
                content: "";
            }

            .swiper-pagination {
                position: relative;
                width: 15rem !important;
                bottom: 1rem;
            }

            .swiper-pagination .swiper-pagination-bullet {
                filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
            }

            .swiper-pagination .swiper-pagination-bullet-active {
                background: var(--primary);
            }
        }
    }
}