// @media (min-width: 1400px) {
//     .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
//         max-width: 90%;
//     }
// }

.app-slider {
    .home-slider-componenet {
        .top-text {
            h1 {
                @media screen and (max-width: 1200px) {
                    font-size: 50px !important;
                    line-height: 60px !important;
                }

                @media screen and (max-width: 991px) {
                    font-size: 40px !important;
                    line-height: 45px !important;
                }

                span {
                    @media screen and (max-width: 1200px) {
                        font-size: 50px !important;
                        line-height: 60px !important;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 40px !important;
                        line-height: 45px !important;
                    }
                }
            }
        }
    }
}

.inovation {
    @media screen and (max-width:991px) {
        margin-top: 2rem !important;
    }
}

.md-space-margin {
    @media screen and (max-width: 991px) {
        margin-top: 40px;
    }
}