.theme-footer {
    &.container {
        max-width: 90%;
    }

    .content {
        background: #0B0F12;
        border-radius: 32px;
        height: 140px;
        padding: 0px 70px;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            height: 100%;
            padding: 40px 0px;
            gap: 20px;
        }

        img {
            width: 100.21px;
            height: 85.77px;
        }

        .list {
            @media screen and (max-width: 500px) {
                flex-direction: column;
            }

            .text {
                a {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    color: #FFFFFF;

                    @media screen and (max-width: 1020px) {
                        font-size: 15px;
                        list-style: 25px;
                    }
                }
            }
        }

        .email {
            background: #FFFFFF;
            border-radius: 50px;
            padding: 10px 15px;

            p {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 26px;
                color: #0B0F12;
            }
        }
    }
}

.theme-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .children {
        flex-grow: 1;
    }
}

.theme-input {
    .form-control {
        box-sizing: border-box;
        border: 0.5px solid #dddfe3;
        width: 100%;
        height: 50px;
        border: 1px solid #ededed !important;
        box-shadow: none !important;
        padding: 0px 20px !important;
        font-size: 14px !important;
        font-family: "Gilroy" !important;
        font-weight: 500 !important;
        line-height: 17px !important;
        background: #FFFFFF;
        border-radius: 11px;


        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 23px;
            color: #58677d;
            opacity: 0.5;

            @media screen and (max-width: 460px) {
                font-size: 12px !important;
            }
        }

        &:hover,
        &:focus,
        &:active {
            box-sizing: border-box;
            background: #ffffff;
            border: 0.5px solid #dddfe3;
            border-radius: 12px;
            box-shadow: none;
            outline: none;
            width: 100%;
            height: 50px;
            box-shadow: none !important;
        }
    }
}

.find-dropdown {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 11px;
    border: none;
    border: 1px solid #ededed !important;
    box-shadow: none !important;
    padding: 0px 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 23px !important;
    color: #58677d !important;
    opacity: 0.5;

    .disabled-option {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 23px !important;
        color: #58677d !important;
        opacity: 0.5;

        @media screen and (max-width: 460px) {
            font-size: 12px !important;
        }
    }

    .options {
        font-size: 14px !important;
        font-family: "Gilroy" !important;
        font-weight: 500 !important;
        line-height: 17px !important;
    }

    // option {
    // }
    &:hover,
    &:focus,
    &:active {
        box-sizing: border-box;
        background: #ffffff;
        border: 0.5px solid #dddfe3;
        border-radius: 12px;
        box-shadow: none;
        outline: none;
        width: 100%;
        height: 50px;
        box-shadow: none !important;
    }
}

// 111.7 + 140