.top-nav-bar {
    background-color: $color-white;
    padding: 0px 27px;
    height: 112px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
    border-radius: 22px;

    @media screen and (max-width: 600px) {
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: calc(var(--bs-gutter-x)* .5);
    }

    .navbar-toggler {
        background-color: #00A9DF;
    }

    .navbar-expand-lg {
        background-color: white;
        width: 100%;
    }

    img {
        width: 100.21px;
        height: 85.77px;
    }

    .navbar-brand {
        @media screen and (max-width: 370px) {
            width: 50%;
        }
    }

    a {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        text-transform: capitalize;
        color: #111111;
    }

    .navbar-nav {
        @media screen and (max-width: 991px) {
            // padding: 10px 20px;
        }
    }

    .active {
        border-top: 8px solid #2AA0D7;
        padding-bottom: 10px;
        color: black;
        padding-top: 41px;

        @media screen and (max-width: 991px) {
            border: none;
            padding: 0;
        }
    }

    .btn-contact-us {
        @include btn-nav-bar
    }

    .navbar-expand .navbar-nav {
        margin: 20px 22px;
    }

}