body {
  margin: 0;
  padding: 0;
  font-family: "Gilroy";
  background-color: #FFFFFF;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: "Lato";
  min-height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif !important;
}

// custom scrollbar

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $theme-scroll-color;
}

::-webkit-scrollbar-thumb {
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;

  li {
    list-style: none;
  }
}

.form-control:focus {}

.container,
.container-fluid {}

// remove blue backgroup chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $theme-color-input inset !important;
  -webkit-text-fill-color: $color-white;

  // color: aqua !important;
}

// .form-control:-webkit-autofill,
// .form-control:-webkit-autofill:hover,
// .form-control:-webkit-autofill:focus,
// .form-control:-webkit-autofill:active {
//     background-color: $theme-color-input;
//     /* Change background color */
//     box-shadow: 0 0 0 30px $theme-color-input inset !important;
//     /* Override autofill box shadow */
//     -webkit-text-fill-color: $color-white;
//     /* Override text color */
// }

// remove icon from number input
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

// remove icon from number input
// input[type=number] {
//     -moz-appearance: textfield;
// }

// cursor
.cursor {
  cursor: pointer;
}

.mt-10 {
  margin-top: 10rem;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.lh-0-ls-5 {
  line-height: unset !important;
  letter-spacing: 5px;
}

.color-dark {
  color: $color-dark;
}

.color-black {
  color: $color-black;
}

.color-blue {
  color: $color-dark-blue;
}

.color-grey {
  color: $color-grey;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.go-back {
  width: 100%;
  text-align: start;
}

// If using react-select, react-date-picker, floating label than give styling here

.line-podcast {
  /* Vector 199 */
  opacity: 0.1;
  border: 1px solid #ffffff;
}

.vertical-line {
  /* Line 1 */
  background: #ffffff;
  opacity: 0.4;
  border: 1.94758px solid #dddddd;
  // transform: rotate(-90deg);

  @media screen and (max-width: 820px) {
    display: none;
  }
}

.custom-back {
  background-color: $side-nav-color;
  min-height: 963px;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating>.form-control:focus~label::after {
  border: none;
  background-color: transparent !important;
}

.form-floating>.form-control:focus~label {
  border: none !important;
  background-color: transparent !important;
}

.form-floating>.form-control:focus {
  border: none !important;
  background-color: $theme-color-input;
}

.vertical-line-video {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;

  @media screen and (max-width: 820px) {
    display: none;
  }
}

.default-align {
  align-items: start !important;
}


.span-kansensus {
  color: #867cf3;
}

.form-floating {
  label {
    font-size: 14px;
    color: $color-grey;
  }

  &.small-input {
    .form-control {
      height: 43px;
    }

    label {
      top: -7px;
    }
  }

  .form-control {
    border-radius: 8px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:not(:placeholder-shown) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:not(:placeholder-shown)~label {
      opacity: 1;
      top: -3px;
      left: 10px;
      background: transparent;
      width: auto;
      height: auto;
      padding: 0 5px;
      color: black;
    }

    &:focus~label {
      opacity: 1;
      top: -3px;
      left: 10px;
      width: auto;
      height: auto;
      padding: 0 5px;
      color: black;
      background: transparent;
    }
  }
}

.form-floating>.form-control {
  padding: 17px;
  min-height: 0px;
}

.form-floating>.form-control:focus~label::after {
  border: none;
  background-color: transparent;
}

.form-control {
  border: 1px solid $color-light-grey;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid $color-white;
    outline: none;
  }
}

.input:-internal-autofill-selected {
  background-color: #19212f !important;
}

.password-input {
  position: relative;

  .eye-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 16px;
    cursor: pointer;
    color: $color-grey;
  }
}

.horizontol-line {
  width: 1177px;
  height: 0px;
  border: 1px solid #333333;
}

.email-button-mobile {
  display: none;
  /* Default to hidden */
}

@media only screen and (max-width: 750px) {
  .email-button-mobile {
    display: inline-block;
    /* or any other display property you prefer */
  }
}

.children {
  min-height: 340px;
}

.store_image {
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 200px;
    height: 60px;
  }
}

.theme-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 100%;
  height: 50px;
  left: 485px;
  top: 1072px;
  background: #0094DA;
  border: none;
  outline: hidden;
}

.theme-spinner {
  border: 3px solid $color-white;
  border-top: 3px solid $border-spinner;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}


.text-red {
  color: $color-red;
}


.validation-txt {
  text-align: start;
  justify-content: start;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px !important;
  margin-left: 10px;
}