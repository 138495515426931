@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: bolder;
    src: url("../fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-BoldItalic";
    font-style: italic;
    font-weight: bolder;
    src: url("../fonts/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-ExtraBold";
    font-style: normal;
    src: url("../fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-ExtraBoldItalic";
    font-style: italic;
    src: url("../fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-SemiBoldItalic";
    font-style: italic;
    font-weight: normal;
    src: url("../fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-Light";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-LightItalic";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/Gilroy-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-MediumItalic";
    font-style: italic;
    font-weight: 500;
    src: url("../fonts/Gilroy-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-SemiBold";
    font-style: normal;
    font-weight: bold;
    src: url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-SemiBoldItalic";
    font-style: italic;
    font-weight: bold;
    src: url("../fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

h1 {
    font-size: 38px;
    font-weight: 500;
}

h3 {
    font-size: 26px;
    font-weight: 500;
}

h4 {
    font-size: 22px;
    font-weight: 500;
}

h5 {
    font-size: 18px;
    font-weight: 400;
}

h6 {
    font-size: 15px;
    font-weight: 500;
}

p {
    font-size: 15px;
    font-weight: 400;
}

a {
    font-size: 15px;
}

span {
    font-size: 13px;
    font-weight: 400;
}

small {
    font-size: 11px;
    font-weight: 400;
}

.form-label,
label {
    font-size: 13px;
    font-weight: 400;
}

.form-check-label {
    font-size: 15px;
    font-weight: 400;
}

textarea,
input,
.form-control {
    font-size: 15px;
    font-weight: 400;
}

button,
.btn {
    font-size: 15px;
    font-weight: 500;
}