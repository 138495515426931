@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bolder;
  src: url("../fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: bolder;
  src: url("../fonts/Gilroy-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  font-style: normal;
  src: url("../fonts/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  font-style: italic;
  src: url("../fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Gilroy-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-LightItalic";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Gilroy-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-MediumItalic";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Gilroy-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}
h1 {
  font-size: 38px;
  font-weight: 500;
}

h3 {
  font-size: 26px;
  font-weight: 500;
}

h4 {
  font-size: 22px;
  font-weight: 500;
}

h5 {
  font-size: 18px;
  font-weight: 400;
}

h6 {
  font-size: 15px;
  font-weight: 500;
}

p {
  font-size: 15px;
  font-weight: 400;
}

a {
  font-size: 15px;
}

span {
  font-size: 13px;
  font-weight: 400;
}

small {
  font-size: 11px;
  font-weight: 400;
}

.form-label,
label {
  font-size: 13px;
  font-weight: 400;
}

.form-check-label {
  font-size: 15px;
  font-weight: 400;
}

textarea,
input,
.form-control {
  font-size: 15px;
  font-weight: 400;
}

button,
.btn {
  font-size: 15px;
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Gilroy";
  background-color: #FFFFFF;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: "Lato";
  min-height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #005b41;
}

::-webkit-scrollbar-thumb {
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;
}
ul li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #19212F inset !important;
  -webkit-text-fill-color: #FFFFFF;
}

.cursor {
  cursor: pointer;
}

.mt-10 {
  margin-top: 10rem;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.lh-0-ls-5 {
  line-height: unset !important;
  letter-spacing: 5px;
}

.color-dark {
  color: #9C8B58;
}

.color-black {
  color: #2D2D2D;
}

.color-blue {
  color: #2C3E57;
}

.color-grey {
  color: #949494;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.go-back {
  width: 100%;
  text-align: start;
}

.line-podcast {
  /* Vector 199 */
  opacity: 0.1;
  border: 1px solid #ffffff;
}

.vertical-line {
  /* Line 1 */
  background: #ffffff;
  opacity: 0.4;
  border: 1.94758px solid #dddddd;
}
@media screen and (max-width: 820px) {
  .vertical-line {
    display: none;
  }
}

.custom-back {
  background-color: #1A212F;
  min-height: 963px;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus {
  border: none !important;
  background-color: #19212F;
}

.vertical-line-video {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  .vertical-line-video {
    display: none;
  }
}

.default-align {
  align-items: start !important;
}

.span-kansensus {
  color: #867cf3;
}

.form-floating label {
  font-size: 14px;
  color: #949494;
}
.form-floating.small-input .form-control {
  height: 43px;
}
.form-floating.small-input label {
  top: -7px;
}
.form-floating .form-control {
  border-radius: 8px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-floating .form-control:not(:-moz-placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:focus ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
  background: transparent;
}

.form-floating > .form-control {
  padding: 17px;
  min-height: 0px;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent;
}

.form-control {
  border: 1px solid #DDDDDD;
  outline: none;
}
.form-control:hover, .form-control:focus {
  box-shadow: none;
  border: 1px solid #FFFFFF;
  outline: none;
}

.input:-internal-autofill-selected {
  background-color: #19212f !important;
}

.password-input {
  position: relative;
}
.password-input .eye-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #949494;
}

.horizontol-line {
  width: 1177px;
  height: 0px;
  border: 1px solid #333333;
}

.email-button-mobile {
  display: none;
  /* Default to hidden */
}

@media only screen and (max-width: 750px) {
  .email-button-mobile {
    display: inline-block;
    /* or any other display property you prefer */
  }
}
.children {
  min-height: 340px;
}

.store_image {
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .store_image {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.store_image img {
  width: 200px;
  height: 60px;
}

.theme-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 100%;
  height: 50px;
  left: 485px;
  top: 1072px;
  background: #0094DA;
  border: none;
  outline: hidden;
}

.theme-spinner {
  border: 3px solid #FFFFFF;
  border-top: 3px solid #3f72af;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text-red {
  color: #D14343;
}

.validation-txt {
  text-align: start;
  justify-content: start;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px !important;
  margin-left: 10px;
}

.top-nav-bar {
  background-color: #FFFFFF;
  padding: 0px 27px;
  height: 112px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
}
@media screen and (max-width: 600px) {
  .top-nav-bar {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.top-nav-bar .navbar-toggler {
  background-color: #00A9DF;
}
.top-nav-bar .navbar-expand-lg {
  background-color: white;
  width: 100%;
}
.top-nav-bar img {
  width: 100.21px;
  height: 85.77px;
}
@media screen and (max-width: 370px) {
  .top-nav-bar .navbar-brand {
    width: 50%;
  }
}
.top-nav-bar a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  text-transform: capitalize;
  color: #111111;
}
.top-nav-bar .active {
  border-top: 8px solid #2AA0D7;
  padding-bottom: 10px;
  color: black;
  padding-top: 41px;
}
@media screen and (max-width: 991px) {
  .top-nav-bar .active {
    border: none;
    padding: 0;
  }
}
.top-nav-bar .btn-contact-us {
  box-sizing: border-box;
  width: 180px;
  height: 60px;
  background: #0094DA;
  border: 1px solid #FFFFFF;
  border-radius: 64px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}
.top-nav-bar .navbar-expand .navbar-nav {
  margin: 20px 22px;
}

.home .top-bannar {
  position: relative;
  min-height: 1051px;
}
@media screen and (max-width: 991px) {
  .home .top-bannar {
    min-height: 100%;
  }
}
.home .top-bannar .home-add-nav {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
}
.home .top-bannar .add-bannar {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
@media screen and (max-width: 460px) {
  .home .top-bannar .add-bannar {
    overflow-x: unset;
  }
}
.home .top-bannar .add-bannar .add-bannar-content .content-column {
  padding: 204px 0px 180px 0px;
}
@media screen and (max-width: 768px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column {
    padding: 0;
    padding-top: 204px;
  }
}
@media screen and (max-width: 600px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column {
    padding-top: 204px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content {
  gap: 19px;
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 71px;
  color: #252525;
}
@media screen and (max-width: 1400px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content h1 {
    font-size: 50px;
    line-height: 55px;
  }
}
@media screen and (max-width: 768px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content h1 {
    font-size: 40px;
    line-height: 45px;
  }
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content h1 span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 71px;
  color: #0094DA;
}
@media screen and (max-width: 1400px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content h1 span {
    font-size: 50px;
    line-height: 55px;
  }
}
@media screen and (max-width: 768px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content h1 span {
    font-size: 40px;
    line-height: 45px;
  }
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content p {
  width: 353px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #000000;
}
@media screen and (max-width: 400px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content p {
    width: 100%;
  }
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content button {
  box-sizing: border-box;
  width: 231.53px;
  height: 60px;
  border: 1px solid #FFFFFF;
  border-radius: 64px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content .buttons {
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content .buttons {
    flex-direction: column;
  }
}
@media screen and (max-width: 460px) {
  .home .top-bannar .add-bannar .add-bannar-content .content-column .content .buttons {
    width: 100%;
    height: auto;
    flex-direction: row;
  }
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content .buttons .download-blue {
  background: #0094DA;
  color: white;
}
.home .top-bannar .add-bannar .add-bannar-content .content-column .content .buttons .download-white {
  background: #FFFFFF;
  border: 1px solid #CECECE;
  border-radius: 64px;
  color: #1C1D1F;
}
.home .top-bannar .add-bannar .add-bannar-content .add-image-column .mobile-add img {
  width: 100dvw;
  max-height: 800px;
}
.home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
  width: 500px;
  height: 500px;
  position: absolute;
  right: 0;
  top: 332px;
}
@media screen and (max-width: 850px) {
  .home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
    width: 360px;
    top: 314px;
  }
}
@media screen and (max-width: 768px) {
  .home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
    top: 309px;
  }
}
@media screen and (max-width: 600px) {
  .home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
    margin-top: 40px;
    top: 350px;
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  .home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
    width: 300px;
    top: 380px;
    height: 300px;
  }
}
@media screen and (max-width: 470px) {
  .home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
    top: 450px;
    height: 300px;
  }
}
@media screen and (max-width: 460px) {
  .home .top-bannar .add-bannar .add-bannar-content .add-image-column-md .mobile-add-image img {
    top: 100%;
    height: auto;
    position: relative;
  }
}
.home .top-bannar .add-bannar .mobile-image img {
  width: 900px;
  height: 1100px;
  position: absolute;
  top: 200px;
  right: 0px;
}
@media screen and (max-width: 1400px) {
  .home .top-bannar .add-bannar .mobile-image img {
    width: 848px;
    height: 848px;
  }
}
@media screen and (max-width: 1100px) {
  .home .top-bannar .add-bannar .mobile-image img {
    width: 727px;
    height: 848px;
  }
}

.about-us .company-overview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about-us .company-overview h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .about-us .company-overview h1 {
    font-size: 40px;
    line-height: 45px;
  }
}
.about-us .company-overview p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .about-us .company-overview p {
    font-size: 13px;
    line-height: 22px;
  }
}
.about-us .content-top h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 68px;
  line-height: 81px;
  text-transform: capitalize;
  color: #252525;
}
@media screen and (max-width: 991px) {
  .about-us .content-top h2 {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .about-us .content-top h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
.about-us .content-top span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 82px;
  text-transform: capitalize;
  color: #0094DA;
}
@media screen and (max-width: 991px) {
  .about-us .content-top span {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .about-us .content-top span {
    font-size: 40px;
    line-height: 45px;
  }
}
.about-us .content-top p {
  width: 70%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .about-us .content {
    gap: 30px;
  }
}
@media screen and (max-width: 991px) {
  .about-us .content .mission-statement {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.about-us .content .mission-statement h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 79px;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .about-us .content .mission-statement h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
@media screen and (max-width: 600px) {
  .about-us .content .mission-statement h2 {
    font-size: 35px;
    line-height: 30px;
  }
}
.about-us .content .mission-statement h2 span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 79px;
  color: #0094DA;
}
@media screen and (max-width: 991px) {
  .about-us .content .mission-statement h2 span {
    font-size: 40px;
    line-height: 45px;
  }
}
@media screen and (max-width: 600px) {
  .about-us .content .mission-statement h2 span {
    font-size: 35px;
    line-height: 40px;
  }
}
.about-us .content .mission-statement p {
  width: 70%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .about-us .image {
    margin-top: 40px;
  }
}
.about-us .image img {
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
  width: 502px;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .about-us .image img {
    width: 520px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .about-us .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .about-us .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .about-us .image img {
    width: 100% !important;
  }
}
.about-us .image-col {
  background-image: url("../images/bg-feature.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.about-us .image-col .image img {
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
  width: 502px;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .about-us .image-col .image img {
    width: 520px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .about-us .image-col .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .about-us .image-col .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .about-us .image-col .image img {
    width: 100% !important;
  }
}

.features .content .discover {
  gap: 20px;
}
.features .content .discover h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 68px;
  line-height: 81px;
  color: #000000;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .features .content .discover h1 {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .features .content .discover h1 {
    font-size: 40px;
    line-height: 45px;
  }
}
.features .content .discover h1 span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 68px;
  line-height: 81px;
  color: #0094DA;
}
@media screen and (max-width: 991px) {
  .features .content .discover h1 span {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .features .content .discover h1 span {
    font-size: 40px;
    line-height: 45px;
  }
}
.features .content .discover p {
  width: 90%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.features .user-friendly .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .features .user-friendly .content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.features .user-friendly .content .text h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 69px;
  color: #0094DA;
}
.features .user-friendly .content .text h1 span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 69px;
  color: #000000;
}
.features .user-friendly .content p {
  padding: 0;
  margin: 0;
  width: 90%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.features .user-friendly .image-col {
  background-image: url("../images/bg-feature.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.features .user-friendly .image-col .image {
  position: relative;
}
.features .user-friendly .image-col .image img {
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
  width: 502px;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .features .user-friendly .image-col .image img {
    width: 400px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .features .user-friendly .image-col .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .features .user-friendly .image-col .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .features .user-friendly .image-col .image img {
    width: 100% !important;
  }
}
.features .your-booking .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.features .your-booking .image-col {
  background-image: url("../images/bg-feature.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.features .your-booking .image-col .image {
  position: relative;
}
.features .your-booking .image-col .image img {
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
  width: 502px;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .features .your-booking .image-col .image img {
    width: 400px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .features .your-booking .image-col .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .features .your-booking .image-col .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .features .your-booking .image-col .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .features .your-booking .content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.features .your-booking .content .text h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 55px;
  color: #000000;
}
.features .your-booking .content .text span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 55px;
  color: #0094DA;
}
.features .your-booking .content p {
  margin-top: 20px;
  padding: 0;
  width: 90%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}

.contact-us .text h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 68px;
  line-height: 81px;
  color: #252525;
}
@media screen and (max-width: 768px) {
  .contact-us .text h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (max-width: 500px) {
  .contact-us .text h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
.contact-us .text p {
  width: 90%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
}
@media screen and (max-width: 500px) {
  .contact-us .text p {
    font-size: 13px;
    line-height: 20px;
  }
}
.contact-us .box .get-in-touch {
  gap: 30px;
  background: #F8F8F8;
  border-radius: 20px;
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch {
    flex-direction: column;
    gap: 0px;
  }
}
.contact-us .box .get-in-touch .form-content {
  padding: 50px 30px 50px 50px;
  justify-content: start;
}
.contact-us .box .get-in-touch .form-content .top-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-us .box .get-in-touch .form-content .top-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 66px;
  color: #070707;
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch .form-content .top-content h1 {
    font-size: 44px;
    line-height: 50px;
  }
}
.contact-us .box .get-in-touch .form-content .top-content h1 span {
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 66px;
  color: #30A8E1;
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch .form-content .top-content h1 span {
    font-size: 44px;
    line-height: 50px;
  }
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch .form-content .top-content h1 span {
    font-size: 38px;
    line-height: 45px;
  }
}
.contact-us .box .get-in-touch .form-content .top-content p {
  width: 70%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #B1B5B6;
  text-align: start;
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch .form-content .top-content p {
    font-size: 13px;
    line-height: 22px;
  }
}
@media screen and (max-width: 600px) {
  .contact-us .box .get-in-touch .form-content .form-contact-us .contacts {
    flex-direction: column;
    gap: 18px;
  }
}
.contact-us .box .get-in-touch .form-content .form-contact-us .contacts .data .text {
  text-align: start;
}
.contact-us .box .get-in-touch .form-content .form-contact-us .contacts .data .text p {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #B1B5B6;
  flex: none;
  flex-grow: 0;
}
.contact-us .box .get-in-touch .form-content .form-contact-us .contacts .data .text span {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #39ACE7;
  flex: none;
  order: 1;
  flex-grow: 0;
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch .location {
    border-radius: 30px;
    height: 300px;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .contact-us .box .get-in-touch .location .map {
    border-radius: 30px;
    width: 100% !important;
  }
}

.terms-and-condition h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 68px;
  text-transform: capitalize;
  color: #252525;
}
@media screen and (max-width: 768px) {
  .terms-and-condition h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (max-width: 500px) {
  .terms-and-condition h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
.terms-and-condition p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
}
@media screen and (max-width: 500px) {
  .terms-and-condition p {
    font-size: 13px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .app-slider .home-slider-componenet .top-text h1 {
    font-size: 50px !important;
    line-height: 60px !important;
  }
}
@media screen and (max-width: 991px) {
  .app-slider .home-slider-componenet .top-text h1 {
    font-size: 40px !important;
    line-height: 45px !important;
  }
}
@media screen and (max-width: 1200px) {
  .app-slider .home-slider-componenet .top-text h1 span {
    font-size: 50px !important;
    line-height: 60px !important;
  }
}
@media screen and (max-width: 991px) {
  .app-slider .home-slider-componenet .top-text h1 span {
    font-size: 40px !important;
    line-height: 45px !important;
  }
}

@media screen and (max-width: 991px) {
  .inovation {
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: 991px) {
  .md-space-margin {
    margin-top: 40px;
  }
}

.application-feature {
  background-image: url("../images/bg-feature.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.application-feature .application_feature_component {
  padding-inline: 20px;
  padding-block: 50px;
}
.application-feature .application_feature_component .headers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.application-feature .application_feature_component .headers h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 59px;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .application-feature .application_feature_component .headers h1 {
    font-size: 40px !important;
    line-height: 45px !important;
  }
}
.application-feature .application_feature_component .headers h1 span {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 59px;
  color: #0094DA;
}
@media screen and (max-width: 991px) {
  .application-feature .application_feature_component .headers h1 span {
    font-size: 40px !important;
    line-height: 45px !important;
  }
}
.application-feature .application_feature_component .mobile_section .image_section img {
  height: 600px;
  -o-object-fit: contain;
     object-fit: contain;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: -95.5584px 95.5584px 119.448px rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}
.application-feature .application_feature_component .mobile_section .description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.application-feature .application_feature_component .mobile_section .description h4 {
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  font-size: 18px;
}
.application-feature .application_feature_component .mobile_section .description .details {
  text-transform: capitalize;
  font-size: 16px;
  color: #9d9d9d;
}
@media screen and (max-width: 575px) {
  .application-feature .application_feature_component .mobile_section .description-md {
    margin-top: 40px;
  }
}

.your-one-stop {
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
}
.your-one-stop .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .your-one-stop .your-one-stop-component-md {
    margin-bottom: 40px;
  }
}
.your-one-stop .image {
  position: relative;
}
.your-one-stop .image img {
  width: 502px;
  height: auto;
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
}
@media screen and (max-width: 1250px) {
  .your-one-stop .image img {
    width: 520px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .your-one-stop .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .your-one-stop .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .your-one-stop .image img {
    width: 100% !important;
  }
}
.your-one-stop .image-content {
  position: relative;
}
.your-one-stop .image-content .image {
  position: relative;
}
.your-one-stop .image-content .image img {
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
  width: 502px;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .your-one-stop .image-content .image img {
    width: 400px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .your-one-stop .image-content .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .your-one-stop .image-content .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .your-one-stop .image-content .image img {
    width: 100% !important;
  }
}
.your-one-stop .image-content .map-img {
  position: absolute;
  top: 14%;
  left: 60%;
}
@media screen and (max-width: 1400px) {
  .your-one-stop .image-content .map-img {
    top: 15%;
    left: 70%;
  }
}
@media screen and (max-width: 1200px) {
  .your-one-stop .image-content .map-img {
    top: 0%;
    left: 65%;
  }
}
@media screen and (max-width: 1050px) {
  .your-one-stop .image-content .map-img {
    top: 0%;
    left: 70%;
  }
}
@media screen and (max-width: 991px) {
  .your-one-stop .image-content .map-img {
    left: 60%;
  }
}
@media screen and (max-width: 768px) {
  .your-one-stop .image-content .map-img {
    left: 70%;
  }
}
.your-one-stop .image-content .map-img img {
  width: 130px;
  height: 287px;
}
@media screen and (max-width: 768px) {
  .your-one-stop .image-content .map-img img {
    width: 100px;
    height: 200px;
  }
}
.your-one-stop .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.your-one-stop .content .text h2 {
  font-family: "Gilroy-ExtraBold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .your-one-stop .content .text h2 {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .your-one-stop .content .text h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
.your-one-stop .content .text span {
  font-family: "Gilroy-ExtraBold" !important;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  color: #0094DA;
}
@media screen and (max-width: 991px) {
  .your-one-stop .content .text span {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .your-one-stop .content .text span {
    font-size: 40px;
    line-height: 45px;
  }
}
.your-one-stop .content p {
  width: 75%;
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  opacity: 0.5;
}

.easy-way .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .easy-way .your-one-stop-component-md {
    margin-bottom: 40px;
  }
}
.easy-way .image {
  position: relative;
}
.easy-way .image img {
  width: 502px;
  height: auto;
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
}
@media screen and (max-width: 1250px) {
  .easy-way .image img {
    width: 520px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .easy-way .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .easy-way .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .easy-way .image img {
    width: 100% !important;
  }
}
.easy-way .image-content {
  position: relative;
}
.easy-way .image-content .image {
  position: relative;
}
.easy-way .image-content .image img {
  filter: drop-shadow(-30.7px 32.6px 14.6px hsla(0, 0%, 0%, 0.27));
  width: 502px;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .easy-way .image-content .image img {
    width: 400px !important;
    height: auto;
  }
}
@media screen and (max-width: 1050px) {
  .easy-way .image-content .image img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .easy-way .image-content .image img {
    width: 520px !important;
  }
}
@media screen and (max-width: 600px) {
  .easy-way .image-content .image img {
    width: 100% !important;
  }
}
.easy-way .image-content .map-img {
  position: absolute;
  top: 14%;
  left: 60%;
}
@media screen and (max-width: 1400px) {
  .easy-way .image-content .map-img {
    top: 15%;
    left: 70%;
  }
}
@media screen and (max-width: 1200px) {
  .easy-way .image-content .map-img {
    top: 0%;
    left: 65%;
  }
}
@media screen and (max-width: 1050px) {
  .easy-way .image-content .map-img {
    top: 0%;
    left: 70%;
  }
}
@media screen and (max-width: 991px) {
  .easy-way .image-content .map-img {
    left: 60%;
  }
}
@media screen and (max-width: 768px) {
  .easy-way .image-content .map-img {
    left: 70%;
  }
}
.easy-way .image-content .map-img img {
  width: 130px;
  height: 287px;
}
@media screen and (max-width: 768px) {
  .easy-way .image-content .map-img img {
    width: 100px;
    height: 200px;
  }
}
.easy-way .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.easy-way .content .text h2 {
  font-family: "Gilroy-ExtraBold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  color: #000000;
}
@media screen and (max-width: 991px) {
  .easy-way .content .text h2 {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .easy-way .content .text h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
.easy-way .content .text span {
  font-family: "Gilroy-ExtraBold" !important;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  color: #0094DA;
}
@media screen and (max-width: 991px) {
  .easy-way .content .text span {
    font-size: 45px;
    line-height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .easy-way .content .text span {
    font-size: 40px;
    line-height: 45px;
  }
}
.easy-way .content p {
  width: 75%;
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  opacity: 0.5;
}
.easy-way .image-col {
  background-image: url("../images/bg-feature.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.app-slider {
  background-color: #F6F6F6;
  padding: 100px 0px 0px 0px;
}
.app-slider .home-slider-componenet .top-text {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.app-slider .home-slider-componenet .top-text h1 {
  font-family: "Gilroy-ExtraBold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 79px;
  color: #0094D9;
}
.app-slider .home-slider-componenet .top-text h1 span {
  font-family: "Gilroy-ExtraBold" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 79px;
  /* identical to box height, or 113% */
  color: #000000;
}
.app-slider .home-slider-componenet .top-text p {
  width: 60%;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.5;
}
.app-slider .home-slider-componenet .slider-component *,
.app-slider .home-slider-componenet .slider-component *::before,
.app-slider .home-slider-componenet .slider-component *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}
.app-slider .home-slider-componenet .slider-component :root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}
.app-slider .home-slider-componenet .slider-component html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media (min-width: 1440px) {
  .app-slider .home-slider-componenet .slider-component html {
    zoom: 1.5;
  }
}
@media (min-width: 2560px) {
  .app-slider .home-slider-componenet .slider-component html {
    zoom: 1.7;
  }
}
@media (min-width: 3860px) {
  .app-slider .home-slider-componenet .slider-component html {
    zoom: 2.5;
  }
}
.app-slider .home-slider-componenet .slider-component ::-webkit-scrollbar {
  width: 1.3rem;
}
.app-slider .home-slider-componenet .slider-component ::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.app-slider .home-slider-componenet .slider-component ::-webkit-scrollbar-thumb:hover {
  background: #222224;
}
.app-slider .home-slider-componenet .slider-component ::-webkit-scrollbar-track {
  background: #f9f9f9;
}
.app-slider .home-slider-componenet .slider-component body {
  font-size: 1.6rem;
  background: var(--bg);
}
.app-slider .home-slider-componenet .slider-component .container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}
.app-slider .home-slider-componenet .slider-component .heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}
.app-slider .home-slider-componenet .slider-component .swiper_container {
  height: 55rem;
  padding: 2rem 0;
  position: relative;
}
.app-slider .home-slider-componenet .slider-component .swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}
@media (max-width: 500px) {
  .app-slider .home-slider-componenet .slider-component .swiper_container {
    height: 47rem;
  }
  .app-slider .home-slider-componenet .slider-component .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .app-slider .home-slider-componenet .slider-component .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}
.app-slider .home-slider-componenet .slider-component .swiper-slide img {
  width: 35rem;
  height: 735px;
  border-radius: 2rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.app-slider .home-slider-componenet .slider-component .swiper-slide-shadow-left,
.app-slider .home-slider-componenet .slider-component .swiper-slide-shadow-right {
  display: none;
}
.app-slider .home-slider-componenet .slider-component .slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-slider .home-slider-componenet .slider-component .slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}
@media (max-width: 990px) {
  .app-slider .home-slider-componenet .slider-component .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}
@media (max-width: 450px) {
  .app-slider .home-slider-componenet .slider-component .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}
@media (max-width: 990px) {
  .app-slider .home-slider-componenet .slider-component .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}
@media (max-width: 450px) {
  .app-slider .home-slider-componenet .slider-component .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}
.app-slider .home-slider-componenet .slider-component .slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}
.app-slider .home-slider-componenet .slider-component .slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}
.app-slider .home-slider-componenet .slider-component .slider-controler .slider-arrow::after {
  content: "";
}
.app-slider .home-slider-componenet .slider-component .swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}
.app-slider .home-slider-componenet .slider-component .swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}
.app-slider .home-slider-componenet .slider-component .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

.theme-footer.container {
  max-width: 90%;
}
.theme-footer .content {
  background: #0B0F12;
  border-radius: 32px;
  height: 140px;
  padding: 0px 70px;
}
@media screen and (max-width: 991px) {
  .theme-footer .content {
    flex-direction: column;
    height: 100%;
    padding: 40px 0px;
    gap: 20px;
  }
}
.theme-footer .content img {
  width: 100.21px;
  height: 85.77px;
}
@media screen and (max-width: 500px) {
  .theme-footer .content .list {
    flex-direction: column;
  }
}
.theme-footer .content .list .text a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
}
@media screen and (max-width: 1020px) {
  .theme-footer .content .list .text a {
    font-size: 15px;
    list-style: 25px;
  }
}
.theme-footer .content .email {
  background: #FFFFFF;
  border-radius: 50px;
  padding: 10px 15px;
}
.theme-footer .content .email p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #0B0F12;
}

.theme-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.theme-layout .children {
  flex-grow: 1;
}

.theme-input .form-control {
  box-sizing: border-box;
  border: 0.5px solid #dddfe3;
  width: 100%;
  height: 50px;
  border: 1px solid #ededed !important;
  box-shadow: none !important;
  padding: 0px 20px !important;
  font-size: 14px !important;
  font-family: "Gilroy" !important;
  font-weight: 500 !important;
  line-height: 17px !important;
  background: #FFFFFF;
  border-radius: 11px;
}
.theme-input .form-control::-moz-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #58677d;
  opacity: 0.5;
}
.theme-input .form-control::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #58677d;
  opacity: 0.5;
}
@media screen and (max-width: 460px) {
  .theme-input .form-control::-moz-placeholder {
    font-size: 12px !important;
  }
  .theme-input .form-control::placeholder {
    font-size: 12px !important;
  }
}
.theme-input .form-control:hover, .theme-input .form-control:focus, .theme-input .form-control:active {
  box-sizing: border-box;
  background: #ffffff;
  border: 0.5px solid #dddfe3;
  border-radius: 12px;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 50px;
  box-shadow: none !important;
}

.find-dropdown {
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  border-radius: 11px;
  border: none;
  border: 1px solid #ededed !important;
  box-shadow: none !important;
  padding: 0px 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 23px !important;
  color: #58677d !important;
  opacity: 0.5;
}
.find-dropdown .disabled-option {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 23px !important;
  color: #58677d !important;
  opacity: 0.5;
}
@media screen and (max-width: 460px) {
  .find-dropdown .disabled-option {
    font-size: 12px !important;
  }
}
.find-dropdown .options {
  font-size: 14px !important;
  font-family: "Gilroy" !important;
  font-weight: 500 !important;
  line-height: 17px !important;
}
.find-dropdown:hover, .find-dropdown:focus, .find-dropdown:active {
  box-sizing: border-box;
  background: #ffffff;
  border: 0.5px solid #dddfe3;
  border-radius: 12px;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 50px;
  box-shadow: none !important;
}/*# sourceMappingURL=style.css.map */